* {
    margin: 0;
    padding: 0;
  }
  
  .portfolio__container {
    background-color: #ffffff;
    flex: 1;
  }
  
  .portfolio__container--center {
    background: #ffffff;
    /*background: linear-gradient(180deg,#f0e7de 0%, rgb(255, 255, 255) 100%);*/
    padding: 1vw;
  }
  
  @media only screen and (max-width: 800px) {
    .portfolio__heading {
      padding-top: 20vh;
      padding-bottom: 8vh;
      font-size: 3vh;
      text-align: center;
      color: rgb(0, 0, 0);
    }
  
    .portfolio__container {
      background-color: #ffffff;
      flex: 1;
    }
  
    .portfolio__container--center {
      background: rgba(185, 155, 185, 0.1);
      padding-top: 55vh;
      /*background: linear-gradient(180deg,#f0e7de 0%, rgb(255, 255, 255) 100%);*/
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
    }
  
    .flexchild--image {
      filter: grayscale(0);
      padding: 2vh 0vw 2vh 5vw;
      align-items: center;
      height: 30vh;
      width: 80vw;
      object-fit: cover;
      
    }
  
    .portfolio__container--center {
      padding: 1vw 5vw;
    }
  
/*     .portfolio__container--bottom {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2vh;
      background-color: #768e83;

    } */
  
  }
  
  @media only screen and (min-width: 801px) {
    
    .portfolio__heading {
      padding-top: 10vh;
      padding-bottom: 8vh;
      font-size: 3vh;
      text-align: center;
      color: #95b3a5;
    }
    
  /*   .portfolio__container--bottom {
      position: sticky;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 80px;
      background-color: #95b3a5;
    } */
    
    .flexparent {
      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      padding: 1vw;
    }
    
    .flexchild {
      list-style-type: none;
      flex-basis: auto;
      background-color:#22372d;
     }
    
    .flexchild--image {
      margin: 2vw;
      filter: grayscale(1);
      height: 300px;
      width: 400px;
      object-fit: cover;
    }
    
    .flexchild--image:hover {
      filter: grayscale(0);
      transform: scale(1.05);
      transition: 1s;
      transition-timing-function: linear;
    }
  
  }